import {
  getStoryblokApi,
  StoryblokComponent,
  useStoryblokState,
} from '@storyblok/react'
import { InferGetServerSidePropsType } from 'next'

import { Layout } from '#components/Layout'
import { withAuthStatus } from '#lib/authentication/pageHelpers'
import { environment } from '#lib/environment.mjs'
import { getFlags } from '#lib/featureFlag'
import { PageStoryblok } from '#types/storyblok-component-types'

const HomePage = ({
  story: initialStory,
}: InferGetServerSidePropsType<
  typeof getServerSideProps
>): JSX.Element | null => {
  const story = useStoryblokState<PageStoryblok>(initialStory, {
    preventClicks: true,
  })

  return (
    <Layout
      pageBlok={story?.content || null}
      padded={false}
      title={
        story?.content.metadata?.title ||
        "Europe's wholesale marketplace for health & beauty retailers | Qogita"
      }
      description={
        story?.content.metadata?.description ||
        'Access exclusive brands, low prices and hot deals from a combined catalog of 400+ suppliers. 10k+ brands available with guaranteed authenticity approved for EU & UK retail. Make procurement simple with our one-stop-shop solution.'
      }
    >
      {story ? (
        <StoryblokComponent blok={story.content} />
      ) : (
        <div>story is null</div>
      )}
    </Layout>
  )
}

export const getServerSideProps = withAuthStatus(async ({ query }) => {
  if (environment.IS_E2E) {
    return { props: {} }
  }

  const featureFlagState = await getFlags()

  const isInStoryblokIframe = Boolean(query._storyblok)

  const slug = 'home'
  const storyblokApi = getStoryblokApi()
  const { data } = await storyblokApi.get(`cdn/stories/${slug}`, {
    version: isInStoryblokIframe ? 'draft' : 'published',
    resolve_links: 'url',
    resolve_relations: ['testimonialSimplePanel.testimonial', 'page.footer'],
  })

  return {
    props: {
      story: data.story,
      featureFlagState,
    },
  }
})

export default HomePage
